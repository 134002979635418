<template>
    <div class="writing_habit_sitting">
        <div class="Secondary_heading">
            <div class="vertical_grain_icon"></div> <span class="title">本班单项平均成绩</span>
        </div>
        <div class="Term_comparison flex-between">
            <div class="Term_comparison_table">
                <el-table :data="AI_Data" style="width: 100%" :stripe="true" :row-style="{ height: '45px' }"
                    :header-row-style="{ height: '45px' }" empty-text="暂无数据">
                    <el-table-column prop="name" label="一级维度" align="center">
                        <template slot-scope="scope">
                            <span style="margin-left: 10px">{{ scope.row.name + '%' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="layout" label="章法占格" align="center">
                        <template slot-scope="scope">
                            <div class="imgUrlbox">
                                <img :class="scope.row.consistenceScore_type" :src="imgUrl[scope.row.consistenceScore_type]"
                                    alt="">
                            </div>
                            <span style="margin-left: 10px">{{ scope.row.consistenceScore + '%' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="structure" label="结构组合" align="center">
                        <template slot-scope="scope">
                            <div class="imgUrlbox">
                                <img :class="scope.row.neatScore_type" :src="imgUrl[scope.row.neatScore_type]" alt="">
                            </div>
                            <span style="margin-left: 10px">{{ scope.row.neatScore + '%' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="form" label="笔画形态" align="center">
                        <template slot-scope="scope">
                            <div class="imgUrlbox">
                                <img :class="scope.row.likelyScore_type" :src="imgUrl[scope.row.likelyScore_type]" alt="">
                            </div>
                            <span style="margin-left: 10px">{{ scope.row.likelyScore + '%' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="complete" label="作品完整度" align="center">
                        <template slot-scope="scope">
                            <div class="imgUrlbox">
                                <img :class="scope.row.completeScore_type" :src="imgUrl[scope.row.completeScore_type]"
                                    alt="">
                            </div>
                            <span style="margin-left: 10px">{{ scope.row.completeScore + '%' }}</span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="dimension_graph" id="dimension_graph2"></div>
        </div>
        <div class="sitting_conclusion conclusion" v-if="AI_Data.length > 0">
            <span class="prefix_title">结论2</span>
            <p>(1)章法占格维度：成绩处于<span class="Comment_highlight">{{ AI_Data[0].consistenceScore_level}}</span>水平，
            <span class="Comment_highlight">{{ AI_Data[0].consistenceScore_average}}</span>年级平均值。
            </p>
            <p>(2)结构组合维度：成绩处于<span class="Comment_highlight">{{ AI_Data[0].neatScore_level }}</span>水平，
            <span class="Comment_highlight">{{ AI_Data[0].neatScore_average }}</span>年级平均值。
            </p>
            <p>(3)笔画形态维度：成绩处于<span class="Comment_highlight">{{ AI_Data[0].likelyScore_level}}</span>水平，
            <span class="Comment_highlight">{{ AI_Data[0].likelyScore_average}}</span>年级平均值。
            </p>
            <p>(4)作品完整度：成绩处于<span class="Comment_highlight">{{ AI_Data[0].completeScore_level}}</span>水平，
            <span class="Comment_highlight">{{ AI_Data[0].completeScore_average}}</span>年级平均值。
            </p>
        </div>
    </div>
</template>

<script>
export default {
    //import引入组件才能使用
    components: {},
    props: {},
    data() {
        return {
            AI_Data: [],
            //箭头类型
            imgUrl: {
                imgUrl1: require('@/assets/newReport/greent.png'),
                imgUrl2: require('@/assets/newReport/redx.png'),
                imgUrl3: require('@/assets/newReport/or_r.png'),
            },
        };
    },
    // 计算属性
    computed: {},
    // 监听data中的数据变化
    watch: {},
    // 方法集合
    methods: {
        receiveData(tableData, graphData, graphData_fixed) {
            this.AI_Data = tableData;
            this.dimension_graph2(graphData, graphData_fixed);
        },
        // AI测评维度雷达图
        dimension_graph2(value, maxValue) {
            this.$echarts.init(document.getElementById("dimension_graph2")).dispose();
            let myChart = this.$echarts.init(
                document.getElementById("dimension_graph2")
            );
            let splitColor = "#fff";
            myChart.setOption({
                tooltip: {
                    show: true,
                    showContent: true,
                    textStyle: {
                        fontSize: this.graphicRatio(12),
                    },
                },
                radar: {
                    center: ["50%", "51%"], // 外圆的位置
                    radius: "60%",
                    axisName: {
                        color: "#033333",
                        fontSize: this.graphicRatio(14),
                        fontWeight: 400,
                        fontFamily: "PingFangSC-Regular,PingFang SC",
                        fontStyle: "normal",
                    },
                    indicator: [{ name: '章法占格', max: maxValue },
                    { name: '结构组合', max: maxValue },
                    { name: '笔画形态', max: maxValue },
                    { name: '作品完整度', max: maxValue },],
                    splitArea: {
                        // 坐标轴在 grid 区域中的分隔区域，默认不显示。
                        show: true,
                        areaStyle: {
                            // 分隔区域的样式设置。
                            color: ["RGBA(228, 229, 235, 1)"], // 分隔区域颜色。分隔区域会按数组中颜色的顺序依次循环设置颜色。默认是一个深浅的间隔色。
                        },
                    },
                    axisLine: {
                        // 指向外圈文本的分隔线样式
                        lineStyle: {
                            color: splitColor,
                        },
                    },
                    splitLine: {
                        lineStyle: {
                            type: "solid",
                            color: splitColor, // 分隔线颜色
                            width: this.graphicRatio(1), // 分隔线线宽
                        },
                    },
                },
                series: [{
                    type: "radar",
                    symbolSize: this.graphicRatio(10),
                    symbol: "none",
                    itemStyle: {
                        borderColor: "FFD900",
                        // color: "#fff",
                        borderWidth: this.graphicRatio(0.2),
                    },
                    lineStyle: {
                        width: this.graphicRatio(1),
                        color: '#9b95f7',
                    },
                    data: [{
                        name: 'AI测评维度成绩',
                        value: value,
                        label: {
                            fontSize: this.graphicRatio(14),
                            formatter: '{value}%'
                        },
                        areaStyle: {
                            color: "rgba(155, 149, 247, 1)"
                        },
                    },],
                },
                ],

            });
            window.addEventListener("resize", () => {
                myChart.resize();
            });
        },
    },
    // 生命周期，创建完成时（可以访问当前this实例）
    created() {

    },
    // 生命周期：挂载完成时（可以访问DOM元素）
    mounted() {

    },
}
</script>
<style lang='less' scoped>
@import url("./less/frontBackaAppraisal_tea.less");
</style>