<template>
    <div>
        <div class="writing_habit_sitting">
            <div class="Secondary_heading">
                <div class="vertical_grain_icon"></div> <span class="title">本班单项成绩等级占比</span>
            </div>
            <div class="grade_proportion">
                <el-table :data="hold_ratio_Data" style="width: 100%" :stripe="true" empty-text="暂无数据"
                    :row-style="{ height: '60px' }" :header-row-style="{ height: '60px' }">
                    <!-- <el-table-column :label="gradeValue + '（' + userNum + '人）单项成绩各等级占比'" align="center"> -->
                    <el-table-column :label="gradeValue + ' 单项成绩各等级占比'" align="center">
                        <el-table-column prop="grade" label="等级" align="center" width="109px">
                        </el-table-column>
                        <el-table-column label="人数占比" align="center" width="109px">
                            <template slot-scope="scope">
                                占比（%）
                            </template>
                        </el-table-column>
                        <el-table-column prop="center_pos" label="居中占位" align="center" :formatter="formatCleanliness">
                        </el-table-column>
                        <el-table-column prop="grid_size" label="占格大小" align="center" :formatter="formatCleanliness">
                        </el-table-column>
                        <el-table-column prop="glyph_grav" label="字形重心" align="center" :formatter="formatCleanliness">
                        </el-table-column>
                        <el-table-column prop="combo_rel" label="组合关系" align="center" :formatter="formatCleanliness">
                        </el-table-column>
                        <el-table-column prop="rewind_rel" label="收放关系" align="center" :formatter="formatCleanliness">
                        </el-table-column>
                        <el-table-column prop="multi_dist" label="多画等距" align="center" :formatter="formatCleanliness">  
                        </el-table-column>
                        <el-table-column prop="stroke_rel" label="笔画关系" align="center" :formatter="formatCleanliness">
                        </el-table-column>
                        <el-table-column prop="integrity" label="作品完整度" align="center" :formatter="formatCleanliness">
                        </el-table-column>
                        <el-table-column prop="totalRatio" label="综合占比" align="center" :formatter="formatCleanliness">
                        </el-table-column>
                    </el-table-column>
                </el-table>
            </div>
            <div class="Term_comparison flex-between">
                <div class="Term_comparison_table">
                    <div class="prevSemGradeComp">与上次{{type}}等级对比</div>
                    <el-table :data="Term_comparison_Data" :row-style="{ height: row_height1 }"
                        :header-row-style="{ height: row_height1 }" empty-text="暂无数据">
                        <el-table-column prop="date" :label="type" align="center">
                            <template slot-scope="scope">
                                <span style="margin-left: 10px">{{ scope.row.date }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="level4" label="优秀" align="center">
                            <template slot-scope="scope">
                                <div class="imgUrlbox">
                                    <img v-if="scope.row.level1.num != '0%'" :class="scope.row.level1.type"
                                        :src="imgUrl[scope.row.level1.type]" alt="">
                                </div>
                                <span style="margin-left: 10px">{{ scope.row.level1.num == '0%' ? '/' :
                                    scope.row.level1.num }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="level3" label="良好" align="center">
                            <template slot-scope="scope">
                                <div class="imgUrlbox"> <img v-if="scope.row.level2.num != '0%'"
                                        :class="scope.row.level2.type" :src="imgUrl[scope.row.level2.type]" alt="">
                                </div>
                                <span style="margin-left: 10px">{{ scope.row.level2.num == '0%' ? '/' :
                                    scope.row.level2.num }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="level2" label="合格" align="center">
                            <template slot-scope="scope">
                                <div class="imgUrlbox"> <img v-if="scope.row.level3.num != '0%'"
                                        :class="scope.row.level3.type" :src="imgUrl[scope.row.level3.type]" alt="">
                                </div>
                                <span style="margin-left: 10px">{{ scope.row.level3.num == '0%' ? '/' :
                                    scope.row.level3.num }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="level1" label="待提升" align="center">
                            <template slot-scope="scope">
                                <div class="imgUrlbox"> <img v-if="scope.row.level4.num != '0%'"
                                        :class="scope.row.level3.type" :src="imgUrl[scope.row.level3.type]" alt="">
                                </div>
                                <span style="margin-left: 10px">{{ scope.row.level4.num == '0%' ? '/' :
                                    scope.row.level4.num }}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div class="Term_comparison_graph" id="Term_comparison_graph"></div>
            </div>
            <div class="sitting_conclusion conclusion" v-if="hold_ratio_Data.length != 0">
                <span class="prefix_title">结论2</span>
                本班学生综合成绩来看，平均成绩<span class="Comment_highlight">{{ k1 }}</span>等级的学生居多。优良率为
                <span class="Comment_highlight">{{ k2 }}%</span>，相对上次{{type}}有所<span class="Comment_highlight">{{ k3 }}</span>。
                <p>(1)、<span>居中占位</span>：<span class="Comment_highlight">{{ computedComment4('center_pos').levelname
                }}</span>水平人数最多，占比为<span class="Comment_highlight">{{
    computedComment4('center_pos').value }}%</span>，较多学生的{{
        comments4['center_pos'][computedComment4('center_pos').levelname] }}
                </p>
                <p>(2)、<span>占格大小</span>：<span class="Comment_highlight">{{ computedComment4('grid_size').levelname
                }}</span>水平人数最多，占比为<span class="Comment_highlight">{{
    computedComment4('grid_size').value }}%</span>，较多学生的{{
        comments4['grid_size'][computedComment4('grid_size').levelname] }}</p>

                <p>(3)、<span>字形重心</span>：<span class="Comment_highlight">{{ computedComment4('glyph_grav').levelname
                }}</span>水平人数最多，占比为<span class="Comment_highlight">{{
    computedComment4('glyph_grav').value }}%</span>，较多学生的{{
        comments4['glyph_grav'][computedComment4('glyph_grav').levelname] }}</p>

                <p>(4)、<span>组合关系</span>：<span class="Comment_highlight">{{ computedComment4('combo_rel').levelname
                }}</span>水平人数最多，占比为<span class="Comment_highlight">{{
    computedComment4('combo_rel').value }}%</span>，较多学生的{{
        comments4['combo_rel'][computedComment4('combo_rel').levelname] }}</p>

                <p>(5)、<span>收放关系</span>：<span class="Comment_highlight">{{ computedComment4('rewind_rel').levelname
                }}</span>水平人数最多，占比为<span class="Comment_highlight">{{
    computedComment4('rewind_rel').value }}%</span>，较多学生的{{
        comments4['rewind_rel'][computedComment4('rewind_rel').levelname] }}</p>

                <p>(6)、<span>多画等距</span>：<span class="Comment_highlight">{{ computedComment4('multi_dist').levelname
                }}</span>水平人数最多，占比为<span class="Comment_highlight">{{
    computedComment4('multi_dist').value }}%</span>，较多学生的{{
        comments4['multi_dist'][computedComment4('multi_dist').levelname] }}</p>

                <p>(7)、<span>笔画关系</span>：<span class="Comment_highlight">{{ computedComment4('stroke_rel').levelname
                }}</span>水平人数最多，占比为<span class="Comment_highlight">{{
    computedComment4('stroke_rel').value }}%</span>，较多学生的{{
        comments4['stroke_rel'][computedComment4('stroke_rel').levelname] }}</p>
                <p>(8)、<span>作品完整度</span>：<span class="Comment_highlight">{{ computedComment4('integrity').levelname
                }}</span>水平人数最多，占比为<span class="Comment_highlight">{{
    computedComment4('integrity').value }}%</span>，较多学生的{{
        comments4['integrity'][computedComment4('integrity').levelname] }}</p>
            </div>
        </div>

        <div class="writing_habit_sitting">
            <div class="Secondary_heading">
                <div class="vertical_grain_icon"></div> <span class="title">本班单项平均成绩</span>
            </div>
            <div class="Term_comparison flex-between">
                <div class="Term_comparison_table Term_comparison_table_new">
                    <el-table :data="AI_Data" style="width: 100%" :stripe="true" :row-style="{ height: '45px' }"
                        :header-row-style="{ height: '45px' }" empty-text="暂无数据">
                        <el-table-column prop="name" label="维度" align="center">
                            <template slot-scope="scope">
                                <span style="margin-left: 10px">{{ scope.row.name }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="centerPos" label="居中占位" align="center">
                            <template slot-scope="scope">
                                <div class="imgUrlbox">
                                    <img :class="scope.row.centerPos_type" :src="imgUrl[scope.row.centerPos_type]" alt="">
                                </div>
                                <span style="margin-left: 10px">{{ scope.row.centerPos }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="gridSize" label="占格大小" align="center">
                            <template slot-scope="scope">
                                <div class="imgUrlbox">
                                    <img :class="scope.row.gridSize_type" :src="imgUrl[scope.row.gridSize_type]" alt="">
                                </div>
                                <span style="margin-left: 10px">{{ scope.row.gridSize }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="glyphGrav" label="字形重心" align="center">
                            <template slot-scope="scope">
                                <div class="imgUrlbox">
                                    <img :class="scope.row.glyphGrav_type" :src="imgUrl[scope.row.glyphGrav_type]" alt="">
                                </div>
                                <span style="margin-left: 10px">{{ scope.row.glyphGrav }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="comboRel" label="组合关系" align="center">
                            <template slot-scope="scope">
                                <div class="imgUrlbox">
                                    <img :class="scope.row.comboRel_type" :src="imgUrl[scope.row.comboRel_type]" alt="">
                                </div>
                                <span style="margin-left: 10px">{{ scope.row.comboRel }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="rewindRel" label="收放关系" align="center">
                            <template slot-scope="scope">
                                <div class="imgUrlbox">
                                    <img :class="scope.row.rewindRel_type" :src="imgUrl[scope.row.rewindRel_type]" alt="">
                                </div>
                                <span style="margin-left: 10px">{{ scope.row.rewindRel }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="multiDist" label="多画等距" align="center">
                            <template slot-scope="scope">
                                <div class="imgUrlbox">
                                    <img :class="scope.row.multiDist_type" :src="imgUrl[scope.row.multiDist_type]" alt="">
                                </div>
                                <span style="margin-left: 10px">{{ scope.row.multiDist }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="strokeRel" label="笔画关系" align="center">
                            <template slot-scope="scope">
                                <div class="imgUrlbox">
                                    <img :class="scope.row.strokeRel_type" :src="imgUrl[scope.row.strokeRel_type]" alt="">
                                </div>
                                <span style="margin-left: 10px">{{ scope.row.strokeRel }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="integrity" label="作品完整度" align="center">
                            <template slot-scope="scope">
                                <div class="imgUrlbox">
                                    <img :class="scope.row.integrity_type" :src="imgUrl[scope.row.integrity_type]" alt="">
                                </div>
                                <span style="margin-left: 10px">{{ scope.row.integrity }}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div class="dimension_graph dimension_graph_new" id="dimension_graph2"></div>
            </div>
            <div class="sitting_conclusion conclusion" v-if="AI_Data.length > 0">
                <span class="prefix_title">结论3</span>
                <p>(1) 居中占位维度：成绩处于<span class="Comment_highlight">{{ AI_Data[0].centerPos_level }}</span>水平，
                    <span class="Comment_highlight">{{ AI_Data[0].centerPos_average }}</span>年级平均值。
                </p>
                <p>(2) 占格大小维度：成绩处于<span class="Comment_highlight">{{ AI_Data[0].gridSize_level }}</span>水平，
                    <span class="Comment_highlight">{{ AI_Data[0].gridSize_average }}</span>年级平均值。
                </p>
                <p>(3) 字形重心维度：成绩处于<span class="Comment_highlight">{{ AI_Data[0].glyphGrav_level }}</span>水平，
                    <span class="Comment_highlight">{{ AI_Data[0].glyphGrav_average }}</span>年级平均值。
                </p>
                <p>(4) 组合关系维度：成绩处于<span class="Comment_highlight">{{ AI_Data[0].comboRel_level }}</span>水平，
                    <span class="Comment_highlight">{{ AI_Data[0].comboRel_average }}</span>年级平均值。
                </p>
                <p>(5) 收放关系维度：成绩处于<span class="Comment_highlight">{{ AI_Data[0].rewindRel_level }}</span>水平，
                    <span class="Comment_highlight">{{ AI_Data[0].rewindRel_average }}</span>年级平均值。
                </p>
                <p>(6) 多画等距维度：成绩处于<span class="Comment_highlight">{{ AI_Data[0].multiDist_level }}</span>水平，
                    <span class="Comment_highlight">{{ AI_Data[0].multiDist_average }}</span>年级平均值。
                </p>
                <p>(7) 笔画关系维度：成绩处于<span class="Comment_highlight">{{ AI_Data[0].strokeRel_level }}</span>水平，
                    <span class="Comment_highlight">{{ AI_Data[0].strokeRel_average }}</span>年级平均值。
                </p>
                <p>(8) 作品完整度维度：成绩处于<span class="Comment_highlight">{{ AI_Data[0].integrity_level }}</span>水平，
                    <span class="Comment_highlight">{{ AI_Data[0].integrity_average }}</span>年级平均值。
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    //import引入组件才能使用
    components: {},
    props: ['userNum', 'gradeValue','type'],
    data() {
        return {
            AI_Data: [],
            // gradeValue: "",
            // userNum: 0,
            //箭头类型
            imgUrl: {
                imgUrl1: require('@/assets/newReport/greent.png'),
                imgUrl2: require('@/assets/newReport/redx.png'),
                imgUrl3: require('@/assets/newReport/or_r.png'),
            },
            Term_comparison_Data: [],
            hold_ratio_Data: [],
            k1: '',
            k2: '',
            k3: '',
            row_height1: '45px',
            comments4: {
                'center_pos': {
                    '优秀': '字占位精准，整字平衡美观。',
                    '良好': '字布局合理，基本做到了占位居中。',
                    '合格': '字略有偏移，要注意第一笔占位，让字整体居中一些。',
                    '待提升': '字严重偏移，要养成居中书写的好习惯，特别是注意第一笔的位置。',
                },
                'grid_size': {
                    '优秀': '字大小完全一致，比例协调。',
                    '良好': '字基本做到大小合理，可注意书写一致性。',
                    '合格': '字过大或过小，要检查握笔是否过高或过低。',
                    '待提升': '字缩放严重，要留意各部件位置，以及部件的高矮和宽窄。',
                },
                'glyph_grav': {
                    '优秀': '字重心挺拔有力，有坚定之感。',
                    '良好': '大部分单字基本做到字形重心稳定平衡。',
                    '合格': '字略有歪斜，要注意笔画方向要做到横平竖直，上下对正，左右对齐，另外字的底部也要保持平稳。',
                    '待提升': '字严重失衡，要注意调整正确的坐姿和握笔姿势，养成先观察后书写的习惯。笔画要做到横平竖直。每写一笔都要参照上一笔来写。',
                },
                'rewind_rel': {
                    '优秀': '字的笔画有长有短。能够收放自如，字形舒展大方。',
                    '良好': '大部分单字能够做到主体紧凑，但作为长笔画的主笔要记得舒展写长。',
                    '合格': '字主体紧凑，要注意字的笔画是有长短区分的，观察好字的长笔画，记得把它们伸展写长。',
                    '待提升': '字收放失度，要养成先观察后书写的好习惯，注意笔画有长有短。笔画间距越准确，字也会美观。',
                },
                'multi_dist': {
                    '优秀': '笔画长短、收放、交接及布白关系准确合理，空间疏密有致。',
                    '良好': '大部分单字同向（横向、纵向和斜向）笔画组间距可做到均匀等距。',
                    '合格': '字布白不均，要保持笔画交接关系准确，争取做到笔画间距均匀。',
                    '待提升': '字空间凌乱，在临摹时坚持“先关系后长短”的原则，提高空间布白意识。',
                },
                'stroke_rel': {
                    '优秀': '笔画位置精准对齐，长度恰当，保持字形的均衡和美感',
                    '良好': '大部分笔画位置准确，长度基本适宜，可能在某些笔画上略显不足或过长。',
                    '合格': '笔画位置大体准确，但可能存在一些偏差，部分笔画长度需要调整，以更好地符合字形规范。',
                    '待提升': '笔画位置存在较大偏差，长度控制不足，需要通过练习来掌握正确的位置和比例关系。',
                },
                'combo_rel': {
                    '优秀': '字能准确处理避让穿插关系，做到穿插合理，字形舒展。继续加油。',
                    '良好': '大部分单字可较好处理部件之间避让穿插的紧密关系，但要注意笔画之间的位置要准确一些。',
                    '合格': '字字形松散，笔画之间要紧凑，注意笔画相互的穿插，部件之间要联系紧密。',
                    '待提升': '字比例失度，要注意观察部件外形的大小和形状，确定好比例关系再书写。',
                },
                'integrity': {
                    '优秀': '可在规定时间内全部完成。',
                    '良好': '完成度较高。',
                    '合格': '部分内容不能正确完成。',
                    '待提升': '大部分内容不能正确完成。',
                },
            }
        };
    },
    // 计算属性
    computed: {},
    // 监听data中的数据变化
    watch: {},
    // 方法集合
    methods: {
        receiveData(tableData, graphData, graphData_fixed) {
            this.AI_Data = tableData;
            this.dimension_graph2(graphData, graphData_fixed);
        },
        // AI测评维度雷达图
        dimension_graph2(value, maxValue) {
            this.$echarts.init(document.getElementById("dimension_graph2")).dispose();
            let myChart = this.$echarts.init(
                document.getElementById("dimension_graph2")
            );
            let splitColor = "#fff";
            myChart.setOption({
                tooltip: {
                    show: true,
                    showContent: true,
                    textStyle: {
                        fontSize: this.graphicRatio(12),
                    },
                },
                radar: {
                    center: ["50%", "51%"], // 外圆的位置
                    radius: "60%",
                    axisName: {
                        color: "#033333",
                        fontSize: this.graphicRatio(14),
                        fontWeight: 400,
                        fontFamily: "PingFangSC-Regular,PingFang SC",
                        fontStyle: "normal",
                    },
                    indicator: [
                        { name: '居中占位', max: maxValue },
                        { name: '占格大小', max: maxValue },
                        { name: '字形重心', max: maxValue },
                        { name: '组合关系', max: maxValue },
                        { name: '收放关系', max: maxValue },
                        { name: '多画等距', max: maxValue },
                        { name: '笔画关系', max: maxValue },
                        { name: '作品完整度', max: maxValue },
                    ],
                    splitArea: {
                        // 坐标轴在 grid 区域中的分隔区域，默认不显示。
                        show: true,
                        areaStyle: {
                            // 分隔区域的样式设置。
                            color: ["RGBA(228, 229, 235, 1)"], // 分隔区域颜色。分隔区域会按数组中颜色的顺序依次循环设置颜色。默认是一个深浅的间隔色。
                        },
                    },
                    axisLine: {
                        // 指向外圈文本的分隔线样式
                        lineStyle: {
                            color: splitColor,
                        },
                    },
                    splitLine: {
                        lineStyle: {
                            type: "solid",
                            color: splitColor, // 分隔线颜色
                            width: this.graphicRatio(1), // 分隔线线宽
                        },
                    },
                },
                series: [{
                    type: "radar",
                    symbolSize: this.graphicRatio(10),
                    symbol: "none",
                    itemStyle: {
                        borderColor: "FFD900",
                        // color: "#fff",
                        borderWidth: this.graphicRatio(0.2),
                    },
                    lineStyle: {
                        width: this.graphicRatio(1),
                        color: '#9b95f7',
                    },
                    data: [{
                        name: 'AI测评维度成绩',
                        value: value,
                        label: {
                            fontSize: this.graphicRatio(14),
                            formatter: '{value}%'
                        },
                        areaStyle: {
                            color: "rgba(155, 149, 247, 1)"
                        },
                    },],
                },
                ],

            });
            window.addEventListener("resize", () => {
                myChart.resize();
            });
        },
        computedComment4(itemname) {

            let weidu = {
                'center_pos': '居中占位',
                'grid_size': '占格大小',
                'glyph_grav': '字形重心',
                'rewind_rel': '收放关系',
                'multi_dist': '多画等距',
                'stroke_rel': '笔画关系',
                'combo_rel': '组合关系',
                'integrity': '作品完整度'
            }
            let arr = [];
            this.hold_ratio_Data.map(item => {
                let obj = {
                    name: weidu[itemname],
                }
                if (item.level == 4) {
                    obj.value = item[itemname];
                    obj.levelname = '优秀'
                }
                if (item.level == 3) {
                    obj.value = item[itemname];
                    obj.levelname = '良好'
                }
                if (item.level == 2) {
                    obj.value = item[itemname];
                    obj.levelname = '合格'
                }
                if (item.level == 1) {
                    obj.value = item[itemname];
                    obj.levelname = '待提升'
                }
                arr.push(obj)
            })
            const maxLevelObj = arr.reduce((max, obj) => (obj.value > max.value ? obj : max), arr[0]);
            return maxLevelObj;
        },
        //判断空数据（本班单项成绩等级占比）
        formatCleanliness(row, column, cellValue, index) {
            return cellValue == 0 ? '/' : cellValue + '%';
        },
        gradesPercentage(resData) {
            this.hold_ratio_Data = resData.data.resultList;
            this.hold_ratio_Data[0].grade = '优秀';
            this.hold_ratio_Data[1].grade = '良好';
            this.hold_ratio_Data[2].grade = '合格';
            this.hold_ratio_Data[3].grade = '待提升';
            this.k1 = resData.data.k1;
            this.k2 = resData.data.k2;
            this.k3 = resData.data.k3;
            // 与上学期等级对比
            let semester_this_compare = {
                date: '本次'+this.type,
                level1: {
                    type: this.judge_type_icon(resData.data.compare[0].level4, resData.data.compare[1].level4),
                    num: resData.data.compare[0].level4 + '%',
                },
                level2: {
                    type: this.judge_type_icon(resData.data.compare[0].level3, resData.data.compare[1].level3),
                    num: resData.data.compare[0].level3 + '%',
                },
                level3: {
                    type: this.judge_type_icon(resData.data.compare[0].level2, resData.data.compare[1].level2),
                    num: resData.data.compare[0].level2 + '%',
                },
                level4: {
                    type: this.judge_type_icon(resData.data.compare[0].level1, resData.data.compare[1].level1),
                    num: resData.data.compare[0].level1 + '%',
                },
            };
            let semester_last_compare = {
                date: '上次'+this.type,
                level1: {
                    type: '',
                    num: resData.data.compare[1].level4 + '%',
                },
                level2: {
                    type: '',
                    num: resData.data.compare[1].level3 + '%',
                },
                level3: {
                    type: '',
                    num: resData.data.compare[1].level2 + '%',
                },
                level4: {
                    type: '',
                    num: resData.data.compare[1].level1 + '%',
                },
            };
            this.Term_comparison_Data = [semester_this_compare, semester_last_compare];
            //图例
            let semester_this = Object.values(resData.data.compare[0]).reverse();
            this.Term_comparison_graph(semester_this)
        },
        judge_type_icon(value1, value2) {
            if (value1 > value2) {
                return 'imgUrl1'
            } else if (value1 < value2) {
                return 'imgUrl2'
            } else {
                return 'imgUrl3'
            }

        },
        //与上学期对比
        Term_comparison_graph(value1) {
            this.$echarts.init(document.getElementById("Term_comparison_graph")).dispose();
            let myChart = this.$echarts.init(
                document.getElementById("Term_comparison_graph")
            );
            myChart.setOption({
                grid: {
                    top: '30%',
                    left: '4%',
                    right: '4%',
                    bottom: '2%',
                    containLabel: true,
                },
                tooltip: {
                    trigger: 'axis',
                    confine: true,
                    padding: this.graphicRatio(5),
                    textStyle: {
                        fontSize: this.graphicRatio(14),
                    },
                },
                legend: {
                    show: true,
                    icon: 'rect',
                    orient: 'horizontal',
                    top: '6%',
                    y: 'center',
                    itemWidth: this.graphicRatio(22),
                    itemHeight: this.graphicRatio(14),
                    textStyle: {
                        fontSize: this.graphicRatio(12),
                    },
                    data: [
                        { name: '本次'+this.type, icon: 'circle' },
                    ],
                },
                xAxis: [
                    {
                        splitLine: {
                            show: false,
                        },
                        axisTick: {
                            show: true,
                            lineStyle: {
                                color: '#dddddd'
                            },
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: '#dddddd'
                            },
                        },
                        axisLabel: {
                            show: true,
                            fontSize: this.graphicRatio(14),
                            color: '#555555',
                            // interval: 'auto',
                        },
                        type: 'category',
                        data: ["优秀", "良好", "合格", "待提升"],
                    },
                ],
                yAxis: [
                    {
                        type: 'value',
                        axisLabel: {
                            show: false,
                        },
                        axisLine: {
                            show: false,
                        },
                        splitLine: {
                            show: false,
                        },
                        axisTick: {
                            show: false,
                        },
                    },
                    {
                        axisLabel: {
                            show: false,
                        },
                        axisLine: {
                            show: false,
                        },
                        splitLine: {
                            show: false,
                        },
                        axisTick: {
                            show: false,
                        },
                    },
                ],
                series: [
                    {
                        name: '本次'+this.type,
                        type: 'pictorialBar',
                        barGap: '0%',
                        barWidth: this.graphicRatio(60),
                        symbol: "path://M12.000,-0.000 C12.000,-0.000 16.074,60.121 22.731,60.121 C26.173,60.121 -3.234,60.121 0.511,60.121 C7.072,60.121 12.000,-0.000 12.000,-0.000 Z",
                        // barGap:10,
                        color: '#247FFF',
                        label: {
                            show: true,
                            position: 'top',
                            fontSize: this.graphicRatio(14),
                            formatter: function (params) {
                                return params.value + '%'
                            },
                        },
                        data: value1,
                        itemStyle: {
                            color: new this.$echarts.graphic.LinearGradient(
                                0,
                                1,
                                0,
                                0,
                                [
                                    {
                                        offset: 0,
                                        color: 'rgba(49, 232, 255, 0.68)', // 0% 处的颜色
                                    },
                                    {
                                        offset: 0.4,
                                        color: 'rgba(149, 231, 212, 1)', // 100% 处的颜色
                                    },
                                ],
                                false
                            ),
                        },
                        emphasis: {
                            disabled: true,
                            focus: 'none',
                        },
                    },
                ],
            });
            window.addEventListener("resize", () => {
                myChart.resize();
            });
        },
    },
    // 生命周期，创建完成时（可以访问当前this实例）
    created() {

    },
    // 生命周期：挂载完成时（可以访问DOM元素）
    mounted() {

    },
}
</script>
<style lang='less' scoped>
@import url("./less/frontBackaAppraisal_tea.less");

.Term_comparison_table_new {
    width: 978px !important;
}

.dimension_graph_new {
    width: 400px !important;
}
</style>